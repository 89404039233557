import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';

import {
  bugoByMemberIdDataAtomFamily,
  bugoByMemberIdStatusAtomFamily,
  bugoDataAtomFamily,
  bugoForStoreDataAtom,
  bugoForStoreStatusAtom,
  bugoStatusAtomFamily,
} from './bugo.atom';

const bugoPopulate = [
  {
    path: 'fevent',
    populate: [
      {
        path: 'deceasedInfo.dpImage',
      },
      {
        path: 'memberOrderList',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },
      {
        path: 'cashAccounts',
      },
      {
        path: 'shopOrders',
        populate: {
          path: 'orderDetail.shopItem',
          populate: [
            {
              path: 'image',
            },
            {
              path: 'shopItemInfo',
              populate: {
                path: 'image',
              },
            },
          ],
        },
      },
    ],
  },
  {
    path: 'bugoBrand',
    populate: [
      {
        path: 'bugoAgency.headerImage',
      },
      {
        path: 'bugoAgency.footerImage',
      },
      {
        path: 'bugoAgencyList.headerImage',
      },
      {
        path: 'bugoAgencyList.footerImage',
      },
    ],
  },
  {
    path: 'user',
    select: ['info.phoneNumber'],
  },
  {
    path: 'member',
    populate: {
      path: 'cashAccount',
    },
  },
];

//* public bugo hook url
export const bugoUrl = (bugoId: string) =>
  `public/bugo/${bugoId}/?${queryString({
    populate: bugoPopulate,
  })}`;

//* public bugo hook
export const useBugoHook = function (bugoId: string, disabled?: boolean) {
  useRecoilQuery(
    bugoStatusAtomFamily(bugoId),
    bugoDataAtomFamily(bugoId),
    bugoUrl(bugoId),
    fetcher,
    disabled,
  );
};

//* store에서 사용하는 bugo hook url
export const bugoForStoreUrl = (bugoId: string) =>
  `public/bugo/${bugoId}/?${queryString({
    populate: [
      {
        path: 'fevent',
        populate: [
          {
            path: 'funeralHomeInfo',
          },
          {
            path: 'roomInfo',
          },
        ],
      },
      {
        path: 'bugoBrand',
      },
      {
        path: 'member',
      },
    ],
  })}`;

//* store에서 사용하는 bugo hook
export const useBugoForStoreHook = (bugoId: string) => {
  useRecoilQuery(
    bugoForStoreStatusAtom,
    bugoForStoreDataAtom,
    bugoForStoreUrl(bugoId),
    fetcher,
  );
};

//* 상주용 bugo hook url
export const bugoByMemberIdUrl = (memberId: string) =>
  `public/bugo/by-member/${memberId}?${queryString({
    populate: bugoPopulate,
  })}`;

//* 상주용 bugo hook by member id
export const useBugoByMemberIdHook = function (memberId: string, disabled?: boolean) {
  useRecoilQuery(
    bugoByMemberIdStatusAtomFamily(memberId),
    bugoByMemberIdDataAtomFamily(memberId),
    bugoByMemberIdUrl(memberId),
    fetcher,
    disabled,
  );
};
