import { Fragment } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getBugoBrandById } from '@shared/api/bugoBrand/bugoBrand.controller';
import {
  tokenSelector,
  userBugoBrandSelector,
  userProfileSelector,
} from '@shared/state/atom/auth.atom';
import { UserCustomTheme } from '@shared/types';
import { useAsync } from 'react-use';

import { customThemeAtom } from '../atom/theme.atom';
import { useAuth } from './useAuth';

const TokenProvider: React.FC<any> = ({ children }) => {
  const { getMe, bugoBrandId } = useAuth();
  //* localStorage 저장하고 이놈이 있는가에 따라서 authenticated로 판단할 수 있다.
  const token = useRecoilValue(tokenSelector);

  //* userProfile 저장하고 이놈으로 role 등을 다 판단
  const setUserProfile = useSetRecoilState(userProfileSelector);
  const setCustomTheme = useSetRecoilState(customThemeAtom);
  const setUserBugoBrand = useSetRecoilState(userBugoBrandSelector);
  useAsync(async () => {
    if (token) {
      const userProfile = await getMe(token);
      if (userProfile) {
        setUserProfile(userProfile);
        setCustomTheme(userProfile.info.theme ?? UserCustomTheme.Default);
      } else {
        // console.log('redirect 해야함');
      }
    }
  }, [token]);
  //* userBugoBrand 가져오기
  useAsync(async () => {
    if (
      bugoBrandId &&
      ['agencyAdminHasNoBugoBrand', 'workerHasNoBugoBrand'].indexOf(bugoBrandId) === -1
    ) {
      const bugoBrand = await getBugoBrandById(bugoBrandId);
      if (bugoBrand) {
        setUserBugoBrand(bugoBrand);
        setCustomTheme(bugoBrand.bugoAgency?.theme as UserCustomTheme);
      }
    }
  }, [bugoBrandId]);

  useAsync(async () => {
    if (
      bugoBrandId &&
      ['agencyAdminHasNoBugoBrand', 'workerHasNoBugoBrand'].indexOf(bugoBrandId) === -1
    ) {
      const bugoBrand = await getBugoBrandById(bugoBrandId);
      if (bugoBrand) {
        setUserBugoBrand(bugoBrand);
        setCustomTheme(bugoBrand.bugoAgency?.theme as UserCustomTheme);
      }
    }
  }, [bugoBrandId]);

  return <Fragment>{children}</Fragment>;
};

export { TokenProvider };
