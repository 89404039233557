//* 상주만 보는 Router hased by MemberId

import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import CourtesyEditPage from '@service/bugo/page/CourtesyEditPage';
import CourtesySenderPage from '@service/bugo/page/CourtesySenderPage';
import CourtesySenderPreviewPage from '@service/bugo/page/CourtesySenderPreviewPage';
import MemberObituaryPage from '@service/bugo/page/MemberObituaryPage';
import { useBugoByMemberIdHook } from '@shared/api/bugo/bugo.hook';
import { bugoByMemberIdStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import _ from 'lodash';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const BugoMemberIdRouter: FC = () => {
  const { memberId } = useParams();
  useBugoByMemberIdHook(memberId ?? 'undefined');
  const memberId_ = memberId ?? 'undefined';
  const bugoByMemberIdState = useRecoilValue(
    bugoByMemberIdStateSelectorFamily(memberId_),
  );

  const bugoTheme = useMemo(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      const bugo = bugoByMemberIdState.data;
      const index = bugo.fevent.bugoBrandAgencyIndex;
      return !_.isNil(index) && bugo.bugoBrand?.bugoAgencyList
        ? bugo.bugoBrand.bugoAgencyList[index]?.theme
        : bugoByMemberIdState.data?.bugoBrand?.bugoAgency?.theme;
    }
  }, [bugoByMemberIdState.data, bugoByMemberIdState.status]);

  return (
    <div className={`max-w-inherit ${bugoTheme}`}>
      <Routes>
        //* 부고 공유 페이지(개인정보동의, 등록도 할 수 있음)
        <Route path="bugo" element={<MemberObituaryPage />} />
        //* 답례글 공유 페이지
        <Route path="courtesy">
          <Route path="" element={<CourtesySenderPage />} />
          <Route path="preview" element={<CourtesySenderPreviewPage />} />

          <Route path="edit" element={<CourtesyEditPage />} />
        </Route>
        <Route path="*" element={<Navigate to="bugo"></Navigate>} />
      </Routes>
    </div>
  );
};

export default BugoMemberIdRouter;
