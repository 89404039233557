import { QueryDataState } from '@shared/interfaces';
import { DeliveryState, LowerRole, PaymentState } from '@shared/types';

import { Bugo } from '../bugo/bugo.interface';
import { BugoBrand } from '../bugoBrand/bugoBrand.interface';
import { Fevent } from '../fevent/fevent.interface';
import { Image } from '../media/media.interface';
import { Shop } from '../shop/shop.interface';
import { ShopItem, ShopItemInBugo } from '../shopItem/shopItem.interface';
import { ShopItemInfo } from '../shopItemInfo/shopItemInfo.interface';
import { User } from '../user/user.interface';
import { FuneralHomeInfo } from './../funeralHomeInfo/funeralHomeInfo.interface';

export type ShopOrder<
  User = string,
  FuneralHomeInfo = string,
  Shop = string,
  Bugo = string,
  Fevent = string,
  DeliveredImage = string,
  ShopItem = string,
  BugoBrand = string,
> = {
  _id: string;
  user: User; // 구매자
  funeralHomeInfo: FuneralHomeInfo; // 장례식장
  shop: Shop; // 꽃집
  bugo?: Bugo; // 모바일 부고장 (nullable)
  bugoBrand?: BugoBrand;
  fevent: Fevent;
  bugoDetail: {
    condolencePhrase: string; // ex) "삼가 고인의 명복을 빕니다."
    senderPhrase: string; // ex) "서울대 전기공학부 일동"
  };
  deliveryDetail: {
    status: DeliveryState; // enum: BeforePreparing, Preparing, Deliverying, Delivered
    deliveredAt?: Date;
    estimatedDeliveredAt: Date;
    senderAddress: string;
    senderName: string;
    senderPhoneNumber: string;
    receiverAddress: string; // funeralHomeInfo.address, (funeralHomeInfo.name, roomInfo?.name ?? fevent.roomInfoEmbed?.name)
    receiverPostalCode: string; // 수령인 주소 우편번호 funeralHomeInfo.postalCode
    receiverName: string; //bugo.member.fullname
    receiverPhoneNumber: string; //bugo.member.phoneNumber
    deliveredImage?: DeliveredImage;
  };
  orderDetail: {
    shopItem: ShopItem;
    shopItemEmbed: {
      name: string;
      priceRetail: number; // 실제 구매가
      priceRetailShow: number; // 표시용 정가
      priceRebate: number; // 리베이트가
      priceRebateWorker: number; //상조직원
      priceRebateAgency: number; //상조본사
      priceWhole: number; // 납품가
    };
    quantity: number;
  };
  paymentDetail: {
    status: PaymentState; // enum: NotPaid(결제 자체를 안했음), Waiting (가상계좌 발급완료 후 입금 대기중), Paid (카드든, 가상계좌든 결제가 완료), Confirmed (구매 확정), Canceled (결제 완료후 취소)
    method: string;
    receiptId: string; // bootpay 결제여부 검증을 위한 key
    receiptUrl: string; // 사용자가 확인 가능한 영수증을 나타내는 url (접속가능)
    cancelId: string; // bootpay cancel시 id
    paymentByCash: number; // 현금으로 결제한 양
    paymentByPoint: number; // 포인트로 결제한 양
    isAccounted?: boolean; // 우리가 꽃집에 정산해줬는지 유무
    accountedDate?: Date;
    isRebated?: boolean; // 우리가 장례식장 또는 상조에 리베이트 줬는지 유무
    rebatedDate?: Date;
    requestedAt?: Date; // 주문을 한 날짜
    purchasedAt?: Date; // 결제를 한 날짜 (bootpay dto.purchased_at)
    canceledAt?: Date; // 결제를 취소된 날짜 (bootpay dto.revoked_at)
    cancelReason?: string; // 결제취소한 이유
    isAbnormalPayment?: boolean; // 이상한 결제 여부 (paymentByCash != bootpay data.price 인 경우에 자동 취소시 true)
    vBank?: string;
  };
  createdAt: Date;
  updatedAt: Date;
};

//* 부고문자에서 사용하는 타입
export type ShopOrderInBugo = ShopOrder<
  string,
  string,
  string,
  string,
  string,
  string,
  ShopItemInBugo
>;

export type ShopOrderListByBugo = ShopOrder<
  string,
  string,
  string,
  string,
  Fevent,
  string
>[];

export type ShopOrderListByBugoState = QueryDataState<ShopOrderListByBugo>;

export type ShopOrderForStore = ShopOrder<
  string,
  string,
  Shop,
  string,
  string,
  Image,
  ShopItem<ShopItemInfo<string, Image>, string, string, Image>
>;

export type ShopOrderForStoreState = QueryDataState<ShopOrderForStore>;

export type ShopOrderListForStore = ShopOrderForStore[];

export type ShopOrderForAgencyAdmin = ShopOrder<
  string,
  FuneralHomeInfo,
  string,
  string,
  Fevent<User>
>;

export type ShopOrderListForStoreState = QueryDataState<ShopOrderListForStore>;

export type ShopOrderListForAgencyAdminState = QueryDataState<ShopOrderForAgencyAdmin[]>;

export type ShopOrderForSeller = ShopOrder<
  User,
  string,
  string,
  Bugo<string, Fevent, BugoBrand>,
  string,
  Image,
  ShopItem<ShopItemInfo<string, Image>, string, string, Image>
>;

export type ShopOrderForSellerAccount = ShopOrder<
  string,
  FuneralHomeInfo,
  string,
  Bugo<User, string, BugoBrand>,
  Fevent
>;

export type ShopOrderForWorker = ShopOrder<
  string,
  FuneralHomeInfo,
  string,
  string,
  Fevent
>;

export enum ShopOrderType {
  Bugo = 'Bugo',
  ReturnGift = 'ReturnGift',
}

export interface ShopOrderCreateDto {
  user: string; // user._id
  shop: string; // shop._id
  type: ShopOrderType;
  bugo: string; // bugo._id
  bugoDetail: {
    condolencePhrase: string;
    senderPhrase: string;
  };
  deliveryDetail: {
    estimatedDeliveredAt: Date;
    senderAddress: string;
    senderName: string;
    senderPhoneNumber: string;
    receiverAddress: string;
    receiverPostalCode: string;
    receiverName: string;
    receiverPhoneNumber: string;
  };
  orderDetail: {
    shopItem: string; // shopItem._id
    quantity: number;
  };
  paymentDetail: {
    paymentByCash: number;
    paymentByPoint: number;
  };
}

export interface ShopOrderUpdate {
  _id: string;
  user?: User; // 구매자
  funeralHomeInfo?: string; // 장례식장
  shop?: string; // 꽃집
  bugo?: string; // 모바일 부고장 (nullable)
  fevent?: string;
  bugoDetail?: {
    condolencePhrase?: string; // ex) "삼가 고인의 명복을 빕니다."
    senderPhrase?: string; // ex) "서울대 전기공학부 일동"
  };
  deliveryDetail?: {
    status?: DeliveryState; // enum: BeforePreparing, Preparing, Deliverying, Delivered
    deliveredAt?: Date;
    estimatedDeliveredAt?: Date;
    senderAddress?: string;
    senderName?: string;
    senderPhoneNumber?: string;
    receiverAddress?: string; // funeralHomeInfo.address, (funeralHomeInfo.name, roomInfo?.name ?? fevent.roomInfoEmbed?.name)
    receiverPostalCode?: string; // 수령인 주소 우편번호 funeralHomeInfo.postalCode
    receiverName?: string; //bugo.member.fullname
    receiverPhoneNumber?: string; //bugo.member.phoneNumber
    deliveredImage?: string;
  };
  orderDetail?: {
    shopItem?: string;
    shopItemEmbed?: {
      name?: string;
      priceRetail?: number; // 실제 구매가
      priceRetailShow?: number; // 표시용 정가
      priceRebate?: number; // 리베이트가
      priceRebateAgency?: number; // 상조 리베이트가
      priceRebateWorker?: number; // 상조 직원 리베이트가
      priceWhole?: number; // 납품가
    };
    quantity?: number;
  };
  paymentDetail?: {
    status?: PaymentState; // enum: NotPaid(결제 자체를 안했음), Waiting (가상계좌 발급완료 후 입금 대기중), Paid (카드든, 가상계좌든 결제가 완료), Confirmed (구매 확정), Canceled (결제 완료후 취소)
    method?: string;
    receiptId?: string; // bootpay 결제여부 검증을 위한 key
    receiptUrl?: string; // 사용자가 확인 가능한 영수증을 나타내는 url (접속가능)
    cancelId?: string; // bootpay cancel시 id
    paymentByCash?: number; // 현금으로 결제한 양
    paymentByPoint?: number; // 포인트로 결제한 양
    isAccounted?: boolean; // 우리가 꽃집에 정산해줬는지 유무
    isRebated?: boolean; // 우리가 장례식장 또는 상조에 리베이트 줬는지 유무
    requestedAt?: Date; // 주문을 한 날짜
    purchasedAt?: Date; // 결제를 한 날짜 (bootpay dto.purchased_at)
    canceledAt?: Date; // 결제를 취소된 날짜 (bootpay dto.revoked_at)
    cancelReason?: string; // 결제취소한 이유
    isAbnormalPayment?: boolean; // 이상한 결제 여부 (paymentByCash != bootpay data.price 인 경우에 자동 취소시 true)
  };
}

export interface ShopOrderUpdateDetailStoreCustomerDto {
  bugoDetail?: {
    condolencePhrase?: string;
    senderPhrase?: string;
  };
  deliveryDetail?: {
    senderAddress?: string;
    senderName?: string;
    senderPhoneNumber?: string;
    receiverAddress?: string;
    receiverPostalCode?: string;
    receiverName?: string;
    receiverPhoneNumber?: string;
  };
}

export interface ShopOrderCancel {
  _id: string;
  cancelReason: string;
}

export interface ShopOrderCancelStoreCustomerDto {
  cancelReason: string;
}

export type StoreRole =
  | LowerRole.Admin
  | LowerRole.StoreCustomer
  | LowerRole.StoreGuest
  | LowerRole.Public;
