//* 조문객이 보는 Router
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import BugoObituaryPage from '@service/bugo/page/BugoObituaryPage';
import BugoObituaryPreviewPage from '@service/bugo/page/BugoObituaryPreviewPage';
import { useBugoHook } from '@shared/api/bugo/bugo.hook';
import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import _ from 'lodash';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const BugoIdRouter: FC = () => {
  const { bugoId } = useParams();
  useBugoHook(bugoId ?? 'undefined', false);

  const bugoState = useRecoilValue(bugoStateSelectorFamily(`${bugoId}`));

  const bugoTheme = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      const bugo = bugoState.data;
      const index = bugo.fevent.bugoBrandAgencyIndex;
      return !_.isNil(index) && bugo.bugoBrand?.bugoAgencyList
        ? bugo.bugoBrand.bugoAgencyList[index]?.theme
        : bugoState.data?.bugoBrand?.bugoAgency?.theme;
    }
  }, [bugoState.data, bugoState.status]);

  return (
    <div className={`max-w-inherit ${bugoTheme}`}>
      <Routes>
        <Route path="" element={<BugoObituaryPage />} />
        <Route path="preview" element={<BugoObituaryPreviewPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default BugoIdRouter;
